export enum BookingSessionConsent {
  GoogleAnalytics = 'analytics',
}

export default interface BookingSession {
  id: string;
  storeId: string;
  services: string[];
  collections: string[];
  watchesRef: string[];
  strapsRef: string[];
  consents: string[];
  message: string;
  language: string;
}

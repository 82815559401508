import { Suspense, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Layout from '../template/components/Layout/Layout';
import AppThemeProvider from './AppThemeProvider';
import configureStore from '../config/store/configureStore';
import RouterScrollToTop from '../config/router/RouterScrollToTop';
import RouterAnalytics from '../config/router/RouterAnalytics';
import '../config/i18n/config';
import AppSentryProvider from './AppSentryProvider';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <AppThemeProvider>
        <AppSentryProvider>
          <Suspense fallback={<Fragment />}>
            <BrowserRouter>
              <RouterScrollToTop />
              <RouterAnalytics />
              <Layout />
            </BrowserRouter>
          </Suspense>
        </AppSentryProvider>
      </AppThemeProvider>
    </Provider>
  );
}

export default App;

import { lazy } from 'react';
import { BOOKING_MANAGE, BOOKING_REGISTRATION } from './uri';

const Registration = lazy(() => import('../pages/RegistrationPage'));
const ManageBooking = lazy(() => import('../pages/ManageBookingPage'));

const routes = [
  // Direct access
  // {
  //   path: `${BOOKING_REGISTRATION}/:target/:session?`,
  //   element: <Registration />,
  // },
  {
    path: `${BOOKING_REGISTRATION}/:session`,
    element: <Registration />,
  },
  // {
  //   path: `${BOOKING_MANAGE}/:target/:guest`,
  //   element: <ManageBooking />,
  // },
  {
    path: `${BOOKING_MANAGE}/:guest`,
    element: <ManageBooking />,
  },
];

export default routes;

import Booking from '../interfaces/Booking';
import Timeslot, { TimeslotObjectMap } from '../interfaces/Timeslot';
import RegisterForm from '../interfaces/RegisterForm';
import Guest from '../interfaces/Guest';
import AdditionalGuest from '../interfaces/AdditionalGuest';
import GuestInvite from '../interfaces/GuestInvite';
import { ApiError } from '../../errors/utils';
import { BookingLanguageResponse, RegisterResponse } from './actions';
import MasterData from '../interfaces/MasterData';
import WalletPass from '../interfaces/WalletPass';
import EmailVerification from '../interfaces/EmailVerification';

/**
 * Get a booking
 */

export const REQUEST_FETCH_BOOKING = 'REQUEST_FETCH_BOOKING';
export const REQUEST_FETCH_BOOKING_FINISHED = 'REQUEST_FETCH_BOOKING_FINISHED';

interface RequestFetchBookingAction {
  type: typeof REQUEST_FETCH_BOOKING;
}

interface RequestFetchBookingFinishedAction {
  type: typeof REQUEST_FETCH_BOOKING_FINISHED;
  payload?: Booking | ApiError;
  error?: boolean;
}

/**
 * Get a booking's language
 */

export const REQUEST_FETCH_BOOKING_LANGUAGE = 'REQUEST_FETCH_BOOKING_LANGUAGE';
export const REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED = 'REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED';

interface RequestFetchBookingLanguageAction {
  type: typeof REQUEST_FETCH_BOOKING_LANGUAGE;
}

interface RequestFetchBookingLanguageFinishedAction {
  type: typeof REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED;
  payload?: BookingLanguageResponse | ApiError;
  error?: boolean;
}

/**
 * Get the available bookable time slots (within a time range of 1 week)
 */

export const REQUEST_FETCH_TIMESLOTS = 'REQUEST_FETCH_TIMESLOTS';
export const REQUEST_FETCH_TIMESLOTS_FINISHED = 'REQUEST_FETCH_TIMESLOTS_FINISHED';

interface RequestFetchTimeslotsAction {
  type: typeof REQUEST_FETCH_TIMESLOTS;
}

interface RequestFetchTimeslotsFinishedAction {
  type: typeof REQUEST_FETCH_TIMESLOTS_FINISHED;
  payload?: TimeslotObjectMap | ApiError;
  error?: boolean;
}

/**
 * Check if bookable time slots are available
 */

export const REQUEST_FETCH_AVAILABILITIES = 'REQUEST_FETCH_AVAILABILITIES';
export const REQUEST_FETCH_AVAILABILITIES_FINISHED = 'REQUEST_FETCH_AVAILABILITIES_FINISHED';

/**
 * Register (book date & time)
 */

export const REQUEST_BOOKING_REGISTER = 'REQUEST_BOOKING_REGISTER';
export const REQUEST_BOOKING_REGISTER_FINISHED = 'REQUEST_BOOKING_REGISTER_FINISHED';

interface RequestBookingRegisterAction {
  type: typeof REQUEST_BOOKING_REGISTER;
}

interface RequestBookingRegisterFinishedAction {
  type: typeof REQUEST_BOOKING_REGISTER_FINISHED;
  payload?: RegisterResponse | ApiError;
  error?: boolean;
}

/**
 * Change booked time slot (date/time and/or additional guests)
 */

export const REQUEST_BOOKING_CHANGE = 'REQUEST_BOOKING_CHANGE';
export const REQUEST_BOOKING_CHANGE_FINISHED = 'REQUEST_BOOKING_CHANGE_FINISHED';

interface RequestBookingChangeAction {
  type: typeof REQUEST_BOOKING_CHANGE;
}

interface RequestBookingChangeFinishedAction {
  type: typeof REQUEST_BOOKING_CHANGE_FINISHED;
  payload?: boolean | ApiError;
  error?: boolean;
}

/**
 * Validate captcha
 */

export const REQUEST_BOOKING_CAPTCHA_VALIDATION = 'REQUEST_BOOKING_CAPTCHA_VALIDATION';
export const REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED = 'REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED';

interface RequestBookingCaptchaValidationAction {
  type: typeof REQUEST_BOOKING_CAPTCHA_VALIDATION;
}

interface RequestBookingCaptchaValidationFinishedAction {
  type: typeof REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED;
  payload?: boolean;
  error?: boolean;
}

/**
 * Booking registration
 */

export const BOOKING_REGISTRATION_SET_TIMESLOT = 'BOOKING_REGISTRATION_SET_TIMESLOT';

interface BookingRegistrationSetTimeslotAction {
  type: typeof BOOKING_REGISTRATION_SET_TIMESLOT;
  payload?: Timeslot;
}

export const BOOKING_REGISTRATION_SET_FORMDATA = 'BOOKING_REGISTRATION_SET_FORMDATA';

interface BookingRegistrationSetFormDataAction {
  type: typeof BOOKING_REGISTRATION_SET_FORMDATA;
  payload: RegisterForm;
}

/**
 * Booking session
 */

export const BOOKING_SET_SESSION = 'BOOKING_SET_SESSION';

interface BookingSetSessionAction {
  type: typeof BOOKING_SET_SESSION;
  payload: string;
}

/**
 * Get a guest
 */

export const REQUEST_FETCH_GUEST = 'REQUEST_FETCH_GUEST';
export const REQUEST_FETCH_GUEST_FINISHED = 'REQUEST_FETCH_GUEST_FINISHED';

interface RequestFetchGuestAction {
  type: typeof REQUEST_FETCH_GUEST;
}

interface RequestFetchGuestFinishedAction {
  type: typeof REQUEST_FETCH_GUEST_FINISHED;
  payload?: Guest | ApiError;
  error?: boolean;
}

/**
 * Cancel a booking of a specific time slot
 */

export const REQUEST_CANCEL_TIME_SLOT_BOOKING = 'REQUEST_CANCEL_TIME_SLOT_BOOKING';
export const REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED = 'REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED';

interface RequestCancelTimeslotBookingAction {
  type: typeof REQUEST_CANCEL_TIME_SLOT_BOOKING;
}

interface RequestCancelTimeslotBookingFinishedAction {
  type: typeof REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED;
  payload?: Guest | ApiError;
  error?: boolean;
}

/**
 * Edit a time slot
 */

export const BOOKING_SET_TIMESLOT_TO_EDIT = 'BOOKING_SET_TIMESLOT_TO_EDIT';

interface BookingSetTimeslotToEditAction {
  type: typeof BOOKING_SET_TIMESLOT_TO_EDIT;
  payload?: GuestInvite;
}

/**
 * Get an ICS file for a given time slot
 */

export const REQUEST_FETCH_TIME_SLOT_ICS = 'REQUEST_FETCH_TIME_SLOT_ICS';
export const REQUEST_FETCH_TIME_SLOT_ICS_FINISHED = 'REQUEST_FETCH_TIME_SLOT_ICS_FINISHED';

interface RequestFetchTimeslotIcsAction {
  type: typeof REQUEST_FETCH_TIME_SLOT_ICS;
}

interface RequestFetchTimeslotIcsFinishedAction {
  type: typeof REQUEST_FETCH_TIME_SLOT_ICS_FINISHED;
  payload?: string | ApiError;
  error?: boolean;
}

export const RESET_TIME_SLOT_ICS = 'RESET_TIME_SLOT_ICS';

interface ResetTimeslotIcsAction {
  type: typeof RESET_TIME_SLOT_ICS;
}

/**
 * Reset registration state
 */

export const RESET_REGISTRATION = 'RESET_REGISTRATION';

interface ResetRegistrationAction {
  type: typeof RESET_REGISTRATION;
  payload: {
    clearFormData: boolean;
  };
}

/**
 * Set additional guests
 */

export const BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS = 'BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS';

interface BookingRegistrationSetAdditionalGuestsAction {
  type: typeof BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS;
  payload: AdditionalGuest[];
}

/**
 * Fetch master data
 */

export const REQUEST_FETCH_MASTERDATA = 'REQUEST_FETCH_MASTERDATA';
export const REQUEST_FETCH_MASTERDATA_FINISHED = 'REQUEST_FETCH_MASTERDATA_FINISHED';

interface RequestFetchMasterDataAction {
  type: typeof REQUEST_FETCH_MASTERDATA;
}

interface RequestFetchMasterDataFinishedAction {
  type: typeof REQUEST_FETCH_MASTERDATA_FINISHED;
  payload?: MasterData | ApiError;
  error?: boolean;
}

/**
 * Email is a duplicate and needs to be verified state
 */

export const BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION = 'BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION';

interface BookingSetEmailDuplicateVerificationAction {
  type: typeof BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION;
  payload: EmailVerification;
}

/**
 * Set the visibility of the wallet pass dialog
 */

export const BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY = 'BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY';

interface BookingSetTWalletPassDialogVisibilityAction {
  type: typeof BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY;
  payload: {
    isVisible: boolean;
    selection?: Timeslot;
  };
}

/**
 * Get a Wallet pass file
 */

export const REQUEST_FETCH_WALLET_PASS = 'REQUEST_FETCH_WALLET_PASS';
export const REQUEST_FETCH_WALLET_PASS_FINISHED = 'REQUEST_FETCH_WALLET_PASS_FINISHED';

interface RequestFetchWalletPassAction {
  type: typeof REQUEST_FETCH_WALLET_PASS;
}

interface RequestFetchWalletPassFinishedAction {
  type: typeof REQUEST_FETCH_WALLET_PASS_FINISHED;
  payload?: WalletPass | ApiError;
  error?: boolean;
}

export const RESET_WALLET_PASS = 'RESET_WALLET_PASS';

interface ResetWalletPassAction {
  type: typeof RESET_WALLET_PASS;
}

/**
 * Reset all state
 */

export const RESET_ALL = 'RESET_ALL';

interface ResetAllAction {
  type: typeof RESET_ALL;
}

/**
 * Push time slot to hold queue (to hold capacity during registration steps)
 */

export const REQUEST_SET_TIME_SLOT_HOLD_QUEUE = 'REQUEST_SET_TIME_SLOT_HOLD_QUEUE';
export const REQUEST_SET_TIME_SLOT_HOLD_QUEUE_FINISHED = 'REQUEST_SET_TIME_SLOT_HOLD_QUEUE_FINISHED';

interface RequestPushTimeslotHoldQueueAction {
  type: typeof REQUEST_SET_TIME_SLOT_HOLD_QUEUE;
}

interface RequestPushTimeslotHoldQueueFinishedAction {
  type: typeof REQUEST_SET_TIME_SLOT_HOLD_QUEUE_FINISHED;
  payload?: string | ApiError;
  error?: boolean;
}

/**
 * Redux State
 */

export interface BookingState {
  booking: Booking | null;
  /**
   * Available time slots within the selected date range
   */
  timeslots: TimeslotObjectMap;
  guest: Guest | null;
  registration: {
    /**
     * Time slot selected by the user (ready to be booked)
     */
    timeslot: Timeslot | null;
    /**
     * ID of entry created in BookingTimeslotHoldQueue
     */
    timeslotHoldQueue: string;
    formData: RegisterForm;
    additionalGuests: AdditionalGuest[];
    confirmed: boolean;
    changeConfirmed: boolean;
    createdGuestUuid: string;
  };
  captchaValidated: boolean;
  currentSession: string;
  timeslotToEdit: GuestInvite | null;
  timeslotCancelled: boolean;
  ics: string;
  walletPass: WalletPass;
  walletPassDialogVisible: boolean;
  walletPassDialogSelection: Timeslot | null;
  masterData: MasterData;
  /**
   * For boutique's booking manage the duplicate email and verification
   */
  emailVerification: EmailVerification;
}

/**
 * Redux Actions Types
 */

export type BookingActionTypes =
  | BookingRegistrationSetAdditionalGuestsAction
  | BookingRegistrationSetFormDataAction
  | BookingRegistrationSetTimeslotAction
  | BookingSetEmailDuplicateVerificationAction
  | BookingSetSessionAction
  | BookingSetTimeslotToEditAction
  | BookingSetTWalletPassDialogVisibilityAction
  | RequestBookingCaptchaValidationAction
  | RequestBookingCaptchaValidationFinishedAction
  | RequestBookingChangeAction
  | RequestBookingChangeFinishedAction
  | RequestBookingRegisterAction
  | RequestBookingRegisterFinishedAction
  | RequestCancelTimeslotBookingAction
  | RequestCancelTimeslotBookingFinishedAction
  | RequestFetchBookingAction
  | RequestFetchBookingFinishedAction
  | RequestFetchBookingLanguageAction
  | RequestFetchBookingLanguageFinishedAction
  | RequestFetchGuestAction
  | RequestFetchGuestFinishedAction
  | RequestFetchMasterDataAction
  | RequestFetchMasterDataFinishedAction
  | RequestFetchTimeslotIcsAction
  | RequestFetchTimeslotIcsFinishedAction
  | RequestFetchTimeslotsAction
  | RequestFetchTimeslotsFinishedAction
  | RequestFetchWalletPassAction
  | RequestFetchWalletPassFinishedAction
  | RequestPushTimeslotHoldQueueAction
  | RequestPushTimeslotHoldQueueFinishedAction
  | ResetRegistrationAction
  | ResetTimeslotIcsAction
  | ResetWalletPassAction
  | ResetAllAction;

import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import Logo from '../../assets/img/audemars-piguet-logo.svg';
import LogoMobile from '../../assets/img/audemars-piguet-logo-mini.svg';
import LogoWhite from '../../assets/img/audemars-piguet-logo-white.svg';
import LogoWhiteMobile from '../../assets/img/audemars-piguet-logo-mini-white.svg';
import { RootState } from '../../../config/store/rootReducer';
import { BOOKING_MANAGE, BOOKING_REGISTRATION } from '../../../features/booking/router/uri';
import useIsDarkTheme from '../../hooks/useIsDarkTheme';
import styles from './Header.module.scss';
import getFullNameLocalized from '../../../features/booking/utils/getFullNameLocalized';
import useGetCurrentLanguage from '../../../features/languages/hooks/useGetCurrentLanguage';
import { useContextTranslation } from '../../../features/languages/hooks/useI18nContext';

export const TOP_ANCHOR_ID = 'back-to-top-anchor';

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    target: window || undefined,
    threshold: 60,
  });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

function Header() {
  const { t } = useTranslation('app');
  const { tManageBookingPage } = useContextTranslation('booking');

  const navigate = useNavigate();
  const matchBookingManage = useMatch(`${BOOKING_MANAGE}/:guest`);
  const currentLanguage = useGetCurrentLanguage();

  const guest = useSelector((state: RootState) => state.booking.guest);
  const currentSession = useSelector((state: RootState) => state.booking.currentSession);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickManage = () => {
    handleClose();
    navigate(`/${BOOKING_MANAGE}/${guest?._id}`);
  };

  const handleClickSignOut = () => {
    handleClose();
    window.location.href = `${import.meta.env.VITE_BASE_URL}/${BOOKING_REGISTRATION}/${currentSession}`;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkTheme = useIsDarkTheme();

  return (
    <Fragment>
      <HideOnScroll>
        <AppBar className={styles.appbar} sx={{ backgroundColor: theme.palette.background.default }}>
          <Toolbar sx={{ backgroundColor: theme.palette.background.default }}>
            <Container maxWidth='lg' className={styles.toolbarContainer}>
              <a href='https://www.audemarspiguet.com/' target='_blank' rel='noreferrer' className={styles.logoLink}>
                <img
                  className={styles.logo}
                  src={isMobile ? (isDarkTheme ? LogoWhiteMobile : LogoMobile) : isDarkTheme ? LogoWhite : Logo}
                  alt='Audemars Piguet'
                />
              </a>
              {guest && currentSession && (
                <Fragment>
                  <IconButton
                    className={styles.account}
                    onClick={handleMenu}
                    size='large'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    color='inherit'
                  >
                    <PersonOutlineIcon />
                  </IconButton>
                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Box sx={{ px: 3, minWidth: '200px' }}>
                      <ListItemText disableTypography>
                        {getFullNameLocalized(guest.firstName, guest.lastName, currentLanguage)}
                      </ListItemText>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    {!matchBookingManage && (
                      <MenuItem onClick={handleClickManage}>
                        <ListItemIcon>
                          <SettingsIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText disableTypography>{tManageBookingPage('Manage my booking')}</ListItemText>
                      </MenuItem>
                    )}
                    <MenuItem onClick={handleClickSignOut}>
                      <ListItemIcon>
                        <LogoutIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText disableTypography>{t('Sign out')}</ListItemText>
                    </MenuItem>
                  </Menu>
                </Fragment>
              )}
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id={TOP_ANCHOR_ID} />
    </Fragment>
  );
}

export default Header;

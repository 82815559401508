import { combineReducers } from 'redux';
import ErrorReducer from './error/reducer';
import RequestingReducer from './requesting/reducer';
import BookingReducer from '../../features/booking/store/reducer';
import LanguagesReducer from '../../features/languages/store/reducer';
import CookiesReducer from '../../features/cookies/store/reducer';
import TemplateReducer from '../../template/store/reducer';
import { ErrorState } from './error/types';
import { RequestingState } from './requesting/types';
import { BookingState } from '../../features/booking/store/types';
import { LanguagesState } from '../../features/languages/store/types';
import { CookiesState } from '../../features/cookies/store/types';
import { TemplateState } from '../../template/store/types';

export interface Store {
  readonly error: ErrorState;
  readonly requesting: RequestingState;
  readonly booking: BookingState;
  readonly languages: LanguagesState;
  readonly cookies: CookiesState;
  readonly template: TemplateState;
}

export const rootReducer = combineReducers<Store>({
  error: ErrorReducer,
  requesting: RequestingReducer,
  booking: BookingReducer,
  languages: LanguagesReducer,
  cookies: CookiesReducer,
  template: TemplateReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

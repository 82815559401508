/**
 * This custom hook returns if the cookies should be disabled or not (depending on the type of event and the
 * environment variables).
 */
function useAreCookiesDisabled() {
  let disabled = true;

  disabled = import.meta.env.VITE_COOKIE_DISABLED === 'false';

  return disabled;
}

export default useAreCookiesDisabled;
